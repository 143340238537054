@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

#header{
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 10;
	background: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(26,26,26,0.3));
	@include props(pt,10);
	@include props(pb,10);


	.inner{
		@include props(w,1166);
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.pcLogo{
			@include props(w,131);
			@include props(h,59);
		}

		.navigation{

			nav{
				display: flex;
				justify-content: space-between;
				align-items: center;

				.snsLinkBox{
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.snsLink{
					&:first-of-type{
						@include props(ml,40);
						@include props(mr,20);

						img{
							@include props(w,29);
							@include props(h,29);
						}
					}

					img{
						@include props(w,34);
						@include props(h,24);
					}
				}
			}
			ul{
				display: flex;
				justify-content: space-between;
				align-items: center;

				li{
					@include props(ml,7);
					@include props(mr,7);

					a{
						@include props(pl,5);
						@include props(pr,5);
						font-weight: bold;
						color: $basicWhiteColor;
					}
				}
				
			}

		}
	}

	@include mq(md){
		@include props(pt,40,750);
		@include props(pb,40,750);


		.inner{
			@include props(w,650,750);
			.hamburgerBtn{
				position: relative;
				z-index: 1000;
				@include props(w,60,750);
				@include props(h,40,750);
	
				span{
					position: absolute;
					@include props(h,4,750);
					width: 100%;
					background-color: $basicWhiteColor;
					transition: all .3s ease-in-out;
	
					&:first-of-type{
						top: 0;
					}
	
					&:nth-of-type(2){
						top: 50%;
						transform: translate(0,-50%);
					}
	
					&:nth-of-type(3){
						bottom: 0%;
					}
				}
			}

			a.mainLogoLink{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				img{
					@include props(w,184,750);
					@include props(h,110,750);
				}
			}

			.navigation{
				position: fixed;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100vh;
				z-index: 100;
				@include props(pt,120,750);
				@include props(pb,120,750);
				@include props(pl,120,750);
				@include props(pr,120,750);
				background-color: #333;
				visibility: hidden;
				opacity: 0;
				transition: all .3s ease-in-out;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				img.spOnly{
					@include props(w,234,750);
					@include props(h,234,750);
					margin: 0 auto;
				}

				nav{
					display: block;
					overflow-y: scroll;
					@include props(mt,80,750);
					@include props(pb,80,750);

					.snsLinkBox{
						justify-content: flex-start;
						@include props(mt,30,750);
						opacity: 0;
						visibility: hidden;
						transform: translate(-10px , 0);
						transition: all .5s 1.1s ease-in-out;

						a{
							@include props(mr,30,750);
							@include props(ml,0,750);
							@include props(mt,0,750);
							@include props(mb,0,750);

							&:first-of-type{
								@include props(mr,30,750);
								@include props(ml,0,750);
								@include props(mt,0,750);
								@include props(mb,0,750);
								img{
									@include props(w,58,750);
									@include props(h,58,750);
								}
							}

							img{
								@include props(w,68,750);
								@include props(h,48,750);
							}
						}
					}
				}
				ul{
					display: flex;
					flex-direction: column;
					justify-content: flex-start;

	
					li{
						margin: 0 auto 1em;
						width: 100%;
						opacity: 0;
						visibility: hidden;
						transform: translate(-10px , 0);
	
						a{
							@include fz_vw(32);
							color: $basicWhiteColor;
							font-weight: bold;
						}
	
						&:nth-child(1){
							transition: all .5s .6s ease-in-out;
						}
						&:nth-child(2){
							transition: all .5s .7s ease-in-out;
						}
						&:nth-child(3){
							transition: all .5s .8s ease-in-out;
						}
						&:nth-child(4){
							transition: all .5s .9s ease-in-out;
						}
						&:nth-child(5){
							transition: all .5s 1s ease-in-out;
						}
					}
				}
			}
		}
			
		&.open{
			.hamburgerBtn{
				span{

					&:first-of-type{
						top: 45%;
						transform: rotate(45deg);
					}
	
					&:nth-of-type(2){
						opacity: 0;
					}
	
					&:nth-of-type(3){
						bottom: 45%;
						transform: rotate(-45deg);
					}
				}
			}

			.navigation{
				opacity: 1;
				visibility: visible;
	
				ul{
	
					li{
						margin-bottom: 1em;
						opacity: 1;
						visibility: visible;
						transform: translate(0);
					}
				}

				.snsLinkBox{
					opacity: 1 !important;
					visibility: visible !important;
					transform: translate(0) !important;
				}
			}
		}
	}
	
}