@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500;600;700&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/*Placeholder*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #333;
  font-size: 62.5%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #707070;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #707070;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #333;
  background-color: #f9f9f9;
  background-image: url(/images/common/common_bg.jpg);
  background-size: cover;
}

body.fixedBody {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
}

body.fixedBody #header.scrollDown .headerContents .navBtnBox {
  background-color: rgba(0, 0, 0, 0);
}

body .fv {
  width: 100vw;
}

body .fv picture {
  width: 100%;
  height: 52.70864vw;
  max-height: 720px;
}

@media screen and (max-width: 767px) {
  body .fv picture {
    height: 97.6vw;
    max-height: 732px;
  }
}

body .fv picture img {
  width: 100%;
  height: 100%;
}

body .fv video {
  width: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */
/* 共通デザイン
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* =====================================
ヘッダー
===================================== */
#header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(26, 26, 26, 0.3));
  padding-top: 0.73206vw;
  padding-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #header {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #header {
    padding-bottom: 10px;
  }
}

#header .inner {
  width: 85.35871vw;
  max-width: 1166px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .inner .pcLogo {
  width: 9.59004vw;
  max-width: 131px;
  height: 4.31918vw;
  max-height: 59px;
}

#header .inner .navigation nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .inner .navigation nav .snsLinkBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .inner .navigation nav .snsLink:first-of-type {
  margin-left: 2.92826vw;
  margin-right: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #header .inner .navigation nav .snsLink:first-of-type {
    margin-left: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #header .inner .navigation nav .snsLink:first-of-type {
    margin-right: 20px;
  }
}

#header .inner .navigation nav .snsLink:first-of-type img {
  width: 2.12299vw;
  max-width: 29px;
  height: 2.12299vw;
  max-height: 29px;
}

#header .inner .navigation nav .snsLink img {
  width: 2.48902vw;
  max-width: 34px;
  height: 1.75695vw;
  max-height: 24px;
}

#header .inner .navigation ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .inner .navigation ul li {
  margin-left: 0.51245vw;
  margin-right: 0.51245vw;
}

@media screen and (min-width: 1366px) {
  #header .inner .navigation ul li {
    margin-left: 7px;
  }
}

@media screen and (min-width: 1366px) {
  #header .inner .navigation ul li {
    margin-right: 7px;
  }
}

#header .inner .navigation ul li a {
  padding-left: 0.36603vw;
  padding-right: 0.36603vw;
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 1366px) {
  #header .inner .navigation ul li a {
    padding-left: 5px;
  }
}

@media screen and (min-width: 1366px) {
  #header .inner .navigation ul li a {
    padding-right: 5px;
  }
}

@media screen and (max-width: 767px) {
  #header {
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #header .inner {
    width: 86.66667vw;
    max-width: 650px;
  }
  #header .inner .hamburgerBtn {
    position: relative;
    z-index: 1000;
    width: 8vw;
    max-width: 60px;
    height: 5.33333vw;
    max-height: 40px;
  }
  #header .inner .hamburgerBtn span {
    position: absolute;
    height: 0.53333vw;
    max-height: 4px;
    width: 100%;
    background-color: #fff;
    transition: all .3s ease-in-out;
  }
  #header .inner .hamburgerBtn span:first-of-type {
    top: 0;
  }
  #header .inner .hamburgerBtn span:nth-of-type(2) {
    top: 50%;
    transform: translate(0, -50%);
  }
  #header .inner .hamburgerBtn span:nth-of-type(3) {
    bottom: 0%;
  }
  #header .inner a.mainLogoLink {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #header .inner a.mainLogoLink img {
    width: 24.53333vw;
    max-width: 184px;
    height: 14.66667vw;
    max-height: 110px;
  }
  #header .inner .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    padding-top: 16vw;
    padding-bottom: 16vw;
    padding-left: 16vw;
    padding-right: 16vw;
    background-color: #333;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation {
    padding-left: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation {
    padding-right: 120px;
  }
}

@media screen and (max-width: 767px) {
  #header .inner .navigation img.spOnly {
    width: 31.2vw;
    max-width: 234px;
    height: 31.2vw;
    max-height: 234px;
    margin: 0 auto;
  }
  #header .inner .navigation nav {
    display: block;
    overflow-y: scroll;
    margin-top: 10.66667vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #header .inner .navigation nav .snsLinkBox {
    justify-content: flex-start;
    margin-top: 4vw;
    opacity: 0;
    visibility: hidden;
    transform: translate(-10px, 0);
    transition: all .5s 1.1s ease-in-out;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  #header .inner .navigation nav .snsLinkBox a {
    margin-right: 4vw;
    margin-left: 0vw;
    margin-top: 0vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox a {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox a {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox a {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox a {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  #header .inner .navigation nav .snsLinkBox a:first-of-type {
    margin-right: 4vw;
    margin-left: 0vw;
    margin-top: 0vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox a:first-of-type {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox a:first-of-type {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox a:first-of-type {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation nav .snsLinkBox a:first-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  #header .inner .navigation nav .snsLinkBox a:first-of-type img {
    width: 7.73333vw;
    max-width: 58px;
    height: 7.73333vw;
    max-height: 58px;
  }
  #header .inner .navigation nav .snsLinkBox a img {
    width: 9.06667vw;
    max-width: 68px;
    height: 6.4vw;
    max-height: 48px;
  }
  #header .inner .navigation ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  #header .inner .navigation ul li {
    margin: 0 auto 1em;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translate(-10px, 0);
  }
  #header .inner .navigation ul li a {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    color: #fff;
    font-weight: bold;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .navigation ul li a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .inner .navigation ul li a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #header .inner .navigation ul li:nth-child(1) {
    transition: all .5s .6s ease-in-out;
  }
  #header .inner .navigation ul li:nth-child(2) {
    transition: all .5s .7s ease-in-out;
  }
  #header .inner .navigation ul li:nth-child(3) {
    transition: all .5s .8s ease-in-out;
  }
  #header .inner .navigation ul li:nth-child(4) {
    transition: all .5s .9s ease-in-out;
  }
  #header .inner .navigation ul li:nth-child(5) {
    transition: all .5s 1s ease-in-out;
  }
  #header.open .hamburgerBtn span:first-of-type {
    top: 45%;
    transform: rotate(45deg);
  }
  #header.open .hamburgerBtn span:nth-of-type(2) {
    opacity: 0;
  }
  #header.open .hamburgerBtn span:nth-of-type(3) {
    bottom: 45%;
    transform: rotate(-45deg);
  }
  #header.open .navigation {
    opacity: 1;
    visibility: visible;
  }
  #header.open .navigation ul li {
    margin-bottom: 1em;
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }
  #header.open .navigation .snsLinkBox {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translate(0) !important;
  }
}

/* =====================================================
フッター
===================================================== */
#footer {
  position: relative;
  width: 85.35871vw;
  max-width: 1166px;
  padding-top: 3.4407vw;
  padding-bottom: 1.46413vw;
  margin: 0 auto;
}

@media screen and (min-width: 1366px) {
  #footer {
    padding-top: 47px;
  }
}

@media screen and (min-width: 1366px) {
  #footer {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #footer {
    width: 86.66667vw;
    max-width: 650px;
    padding-bottom: 5.33333vw;
    padding-top: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer {
    padding-top: 120px;
  }
}

#footer:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100%;
  background-color: #333;
  z-index: -1;
}

#footer .mainInfonation nav {
  display: flex;
  align-items: flex-start;
}

#footer .mainInfonation nav:before {
  content: "";
  background-image: url(/images/common/footer_logo.svg);
  display: block;
  width: 7.32064vw;
  max-width: 100px;
  height: 7.32064vw;
  max-height: 100px;
  margin-right: 7.32064vw;
  background-size: contain;
}

@media screen and (min-width: 1366px) {
  #footer .mainInfonation nav:before {
    margin-right: 100px;
  }
}

#footer .mainInfonation nav ul {
  margin-right: 3.66032vw;
}

@media screen and (min-width: 1366px) {
  #footer .mainInfonation nav ul {
    margin-right: 50px;
  }
}

#footer .mainInfonation nav ul li {
  margin: .2em auto;
}

#footer .mainInfonation nav ul li a {
  display: inline-block;
  padding: .3em .2em;
  color: #fff;
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #footer .mainInfonation nav ul li a {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #footer .mainInfonation nav ul li a {
    font-size: 2.13333vw;
  }
}

#footer .mainInfonation nav .snsLink {
  display: flex;
  align-items: center;
}

#footer .mainInfonation nav .snsLink a {
  margin-right: 1.83016vw;
}

@media screen and (min-width: 1366px) {
  #footer .mainInfonation nav .snsLink a {
    margin-right: 25px;
  }
}

#footer .mainInfonation nav .snsLink a:first-of-type {
  margin-left: 2.92826vw;
  margin-right: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #footer .mainInfonation nav .snsLink a:first-of-type {
    margin-left: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .mainInfonation nav .snsLink a:first-of-type {
    margin-right: 20px;
  }
}

#footer .mainInfonation nav .snsLink a:first-of-type img {
  width: 2.12299vw;
  max-width: 29px;
  height: 2.12299vw;
  max-height: 29px;
}

#footer .mainInfonation nav .snsLink a img {
  width: 2.48902vw;
  max-width: 34px;
  height: 1.75695vw;
  max-height: 24px;
}

#footer small {
  display: block;
  color: #fff;
  text-align: center;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  margin-top: 6.14934vw;
}

@media screen and (min-width: 1366px) {
  #footer small {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #footer small {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #footer small {
    margin-top: 84px;
  }
}

@media screen and (max-width: 767px) {
  #footer small {
    margin-top: 0vw;
    font-size: 3.2rem;
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer small {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer small {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer small {
    font-size: 4.26667vw;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
/* ボタン装飾------------------------------ */
.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

.mainContentLogo {
  background-image: url(/images/common/main_logo_pc.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 7.61347vw;
  max-width: 104px;
  height: 7.61347vw;
  max-height: 104px;
  margin-top: 5.85652vw;
  margin-bottom: 5.12445vw;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1366px) {
  .mainContentLogo {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1366px) {
  .mainContentLogo {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) {
  .mainContentLogo {
    background-image: url(/images/common/main_logo.svg);
    width: 11.46667vw;
    max-width: 86px;
    height: 11.46667vw;
    max-height: 86px;
    margin-top: 6.66667vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .mainContentLogo {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .mainContentLogo {
    margin-bottom: 50px;
  }
}

.fadeIn {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.fadeIn.active {
  opacity: 1;
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop .fv {
  position: relative;
}

#indexTop .fv:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30.74671vw;
  max-width: 420px;
  height: 10.68814vw;
  max-height: 146px;
  background-image: url(/images/index/index_movie_logo_pc.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

#indexTop .fv .soundBtnBox {
  position: absolute;
  bottom: 0.73206vw;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 85.35871vw;
  max-width: 1166px;
}

@media screen and (min-width: 1366px) {
  #indexTop .fv .soundBtnBox {
    bottom: 10px;
  }
}

#indexTop .fv .soundBtnBox #playBtn {
  width: 8.78477vw;
  max-width: 120px;
  height: 3.07467vw;
  max-height: 42px;
  margin: 0 0 0 auto;
  background-repeat: no-repeat;
  background-size: contain;
}

#indexTop .fv .soundBtnBox #playBtn:hover {
  cursor: pointer;
}

#indexTop .fv .soundBtnBox #playBtn.soundOff {
  background-image: url(/images/index/index_soundoff.svg);
}

#indexTop .fv .soundBtnBox #playBtn.soundOn {
  background-image: url(/images/index/index_soundon.svg);
}

@media screen and (max-width: 767px) {
  #indexTop .fv:after {
    background-image: url(/images/index/index_movie_logo.svg);
    width: 20vw;
    max-width: 150px;
    height: 62vw;
    max-height: 465px;
    top: inherit;
    bottom: 12vw;
    transform: translate(-50%, 0%);
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .fv:after {
    bottom: 90px;
  }
}

#indexTop .read {
  position: relative;
  margin: 0 auto;
  margin-top: 5.12445vw;
  margin-bottom: 4.61201vw;
  width: 85.35871vw;
  max-width: 1166px;
  padding-top: 0.87848vw;
  padding-bottom: 0.87848vw;
  letter-spacing: 3px;
  line-height: 3;
}

@media screen and (min-width: 1366px) {
  #indexTop .read {
    margin-top: 70px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .read {
    margin-bottom: 63px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .read {
    padding-top: 12px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .read {
    padding-bottom: 12px;
  }
}

#indexTop .read:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100vw;
  height: 100%;
  background-color: #fff;
}

#indexTop .read .txtBox {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

#indexTop .read h2 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  font-weight: bold;
  writing-mode: vertical-rl;
  margin-left: 1em;
}

@media screen and (min-width: 1366px) {
  #indexTop .read h2 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .read h2 {
    font-size: 3.2vw;
  }
}

#indexTop .read p {
  writing-mode: vertical-rl;
  padding-top: 1em;
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .read p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .read p {
    font-size: 2.13333vw;
  }
}

#indexTop .read p span {
  color: #9D0202;
  font-weight: bold;
}

#indexTop .read p:nth-of-type(2) {
  margin: 0 1em;
}

@media screen and (max-width: 767px) {
  #indexTop .read {
    width: 86.66667vw;
    max-width: 650px;
    margin-bottom: 2.66667vw;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .read {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .read:before {
    display: none;
  }
  #indexTop .read .txtBox {
    display: block;
  }
  #indexTop .read h2 {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    font-weight: bold;
    writing-mode: inherit;
    margin-left: 0;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .read h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .read h2 {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .read p {
    text-align: center;
    writing-mode: inherit;
    padding-top: 1em;
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .read p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .read p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .read p span {
    color: #9D0202;
  }
  #indexTop .read p:nth-of-type(2) {
    margin: 1em auto;
    text-align: start;
    display: inline-block;
  }
}

#indexTop .navBox {
  width: 85.35871vw;
  max-width: 1166px;
  margin: 0 auto;
  margin-bottom: 5.12445vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .navBox {
    margin-bottom: 70px;
  }
}

#indexTop .navBox ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

#indexTop .navBox ul li {
  width: calc(100% / 2 - 0.58vw);
  position: relative;
}

#indexTop .navBox ul li:nth-child(1), #indexTop .navBox ul li:nth-child(2) {
  margin-bottom: 1.83016vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .navBox ul li:nth-child(1), #indexTop .navBox ul li:nth-child(2) {
    margin-bottom: 25px;
  }
}

#indexTop .navBox ul li a picture {
  width: 100%;
  height: 17.56955vw;
  max-height: 240px;
}

#indexTop .navBox ul li a:after {
  content: "お品書き";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.4rem;
  font-size: 1.75695vw;
  color: #fff;
  width: 100%;
  text-align: center;
  line-height: 2;
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 3;
}

@media screen and (min-width: 1366px) {
  #indexTop .navBox ul li a:after {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .navBox ul li a:after {
    font-size: 3.2vw;
  }
}

#indexTop .navBox ul li a img {
  transition: all .3s ease-in-out;
  width: 100%;
  height: 100%;
}

#indexTop .navBox ul li a:hover img {
  opacity: .7;
}

#indexTop .navBox ul li:nth-child(2) a:after {
  content: "料理屋 染川について";
}

#indexTop .navBox ul li:nth-child(3) a:after {
  content: "ご予約";
}

#indexTop .navBox ul li:nth-child(4) a:after {
  content: "アクセス";
}

@media screen and (max-width: 767px) {
  #indexTop .navBox {
    width: 86.66667vw;
    max-width: 650px;
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .navBox {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .navBox ul li {
    width: 100%;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .navBox ul li {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .navBox ul li:nth-child(1), #indexTop .navBox ul li:nth-child(2) {
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .navBox ul li:nth-child(1), #indexTop .navBox ul li:nth-child(2) {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .navBox ul li a picture {
    width: 73.86667vw;
    max-width: 554px;
    height: 34.13333vw;
    max-height: 256px;
  }
  #indexTop .navBox ul li a:after {
    content: "お品書き";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.2rem;
    font-size: 2.34261vw;
    color: #fff;
    width: 100%;
    text-align: center;
    line-height: 2;
    background-color: rgba(51, 51, 51, 0.5);
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .navBox ul li a:after {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .navBox ul li a:after {
    font-size: 4.26667vw;
  }
}

#indexTop .instagramBox {
  width: 56.22255vw;
  max-width: 768px;
  margin-bottom: 7.32064vw;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagramBox {
    margin-bottom: 100px;
  }
}

#indexTop .instagramBox h2 {
  text-align: center;
  font-size: 2.4rem;
  font-size: 1.75695vw;
  margin-bottom: 2.63543vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagramBox h2 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagramBox h2 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagramBox h2 {
    margin-bottom: 36px;
  }
}

#indexTop .instagramBox #instagramContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

#indexTop .instagramBox #instagramContent .instagramContentList {
  width: 17.56955vw;
  max-width: 240px;
  height: 17.56955vw;
  max-height: 240px;
}

#indexTop .instagramBox #instagramContent .instagramContentList a {
  width: 100%;
  height: 100%;
}

#indexTop .instagramBox #instagramContent .instagramContentList a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#indexTop .instagramBox #instagramContent .instagramContentList:nth-child(1), #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(2), #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(3) {
  margin-bottom: 1.83016vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(1), #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(2), #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(3) {
    margin-bottom: 25px;
  }
}

#indexTop .instagramBox #instagramContent .instaError {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagramBox #instagramContent .instaError {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagramBox #instagramContent .instaError {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagramBox {
    width: 86.66667vw;
    max-width: 650px;
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagramBox {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagramBox h2 {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagramBox h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .instagramBox h2 {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagramBox h2 {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagramBox #instagramContent .instagramContentList {
    width: 26.66667vw;
    max-width: 200px;
    height: 26.66667vw;
    max-height: 200px;
  }
  #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(1), #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(2), #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(3) {
    margin-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(1), #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(2), #indexTop .instagramBox #instagramContent .instagramContentList:nth-child(3) {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagramBox #instagramContent .instaError {
    font-size: 2.4rem;
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagramBox #instagramContent .instaError {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .instagramBox #instagramContent .instaError {
    font-size: 3.2vw;
  }
}

#aboutTop .ownerBox {
  width: 85.35871vw;
  max-width: 1166px;
  margin-bottom: 7.32064vw;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

@media screen and (min-width: 1366px) {
  #aboutTop .ownerBox {
    margin-bottom: 100px;
  }
}

#aboutTop .ownerBox img {
  width: 50.36603vw;
  max-width: 688px;
  object-fit: cover;
}

#aboutTop .ownerBox .inner {
  padding: 2.34261vw;
  background-color: #fff;
}

@media screen and (min-width: 1366px) {
  #aboutTop .ownerBox .inner {
    padding: 32px;
  }
}

#aboutTop .ownerBox .inner strong {
  font-weight: bold;
  font-size: 2rem;
  font-size: 1.46413vw;
  display: block;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #aboutTop .ownerBox .inner strong {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .ownerBox .inner strong {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .ownerBox .inner strong {
    margin-bottom: 30px;
  }
}

#aboutTop .ownerBox .inner p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  color: #707070;
}

@media screen and (min-width: 1366px) {
  #aboutTop .ownerBox .inner p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .ownerBox .inner p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .ownerBox {
    width: 86.66667vw;
    max-width: 650px;
    margin-bottom: 18.66667vw;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .ownerBox {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .ownerBox img {
    width: 86.66667vw;
    max-width: 650px;
  }
  #aboutTop .ownerBox .inner {
    padding-top: 8vw;
    padding-bottom: 0vw;
    padding-left: 0vw;
    padding-right: 0vw;
    background-color: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .ownerBox .inner {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .ownerBox .inner {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .ownerBox .inner {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .ownerBox .inner {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .ownerBox .inner strong {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .ownerBox .inner strong {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .ownerBox .inner strong {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .ownerBox .inner strong {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .ownerBox .inner p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .ownerBox .inner p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .ownerBox .inner p {
    font-size: 3.73333vw;
  }
}

#accessTop .infomationBox {
  width: 85.35871vw;
  max-width: 1166px;
  margin-bottom: 7.32064vw;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #accessTop .infomationBox {
    margin-bottom: 100px;
  }
}

#accessTop .infomationBox:before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: 7.32064vw;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  height: 100%;
  z-index: -1;
}

@media screen and (min-width: 1366px) {
  #accessTop .infomationBox:before {
    top: 100px;
  }
}

#accessTop .infomationBox .inner {
  text-align: center;
  display: inline-block;
  margin-top: 2.92826vw;
  margin-bottom: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #accessTop .infomationBox .inner {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #accessTop .infomationBox .inner {
    margin-bottom: 80px;
  }
}

#accessTop .infomationBox .inner p {
  text-align: start;
  margin-bottom: 1.90337vw;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  color: #707070;
}

@media screen and (min-width: 1366px) {
  #accessTop .infomationBox .inner p {
    margin-bottom: 26px;
  }
}

@media screen and (min-width: 1366px) {
  #accessTop .infomationBox .inner p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #accessTop .infomationBox .inner p {
    font-size: 2.13333vw;
  }
}

#accessTop .infomationBox .inner p:last-of-type {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1366px) {
  #accessTop .infomationBox .inner p:last-of-type {
    margin-bottom: 0px;
  }
}

#accessTop .infomationBox iframe {
  width: 100%;
  height: 35.13909vw;
  max-height: 480px;
  border: 1px solid #C6C6C6;
}

@media screen and (max-width: 767px) {
  #accessTop .infomationBox {
    width: 86.66667vw;
    max-width: 650px;
    margin-bottom: 18.66667vw;
  }
  #accessTop .infomationBox::before {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #accessTop .infomationBox {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) {
  #accessTop .infomationBox .inner {
    margin-top: 5.33333vw;
    margin-bottom: 8vw;
    text-align: start;
    display: block;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #accessTop .infomationBox .inner {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #accessTop .infomationBox .inner {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #accessTop .infomationBox .inner p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #accessTop .infomationBox .inner p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #accessTop .infomationBox .inner p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #accessTop .infomationBox .inner p {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #accessTop .infomationBox iframe {
    height: 53.33333vw;
    max-height: 400px;
  }
}

#reserveTop .infomationBox {
  width: 85.35871vw;
  max-width: 1166px;
  margin-bottom: 7.32064vw;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox {
    margin-bottom: 100px;
  }
}

#reserveTop .infomationBox p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  color: #707070;
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox p {
    font-size: 2.13333vw;
  }
}

#reserveTop .infomationBox .telBox {
  margin-top: 2.19619vw;
  margin-bottom: 5.12445vw;
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox .telBox {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox .telBox {
    margin-bottom: 70px;
  }
}

#reserveTop .infomationBox .telBox a {
  font-size: 2.4rem;
  font-size: 1.75695vw;
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox .telBox a {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .telBox a {
    font-size: 3.2vw;
  }
}

#reserveTop .infomationBox .inner {
  text-align: center;
  display: inline-block;
  margin-bottom: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox .inner {
    margin-bottom: 80px;
  }
}

#reserveTop .infomationBox .inner p {
  text-align: start;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  color: #707070;
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox .inner p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .inner p {
    font-size: 1.86667vw;
  }
}

#reserveTop .infomationBox .linkBtnBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 37.70132vw;
  max-width: 515px;
  margin: 0 auto;
}

#reserveTop .infomationBox .linkBtnBox .linkBtn {
  width: calc(100% / 2 - 1.6105vw);
  border: 1px solid #333;
  border-radius: 30px;
  background-color: #fff;
  transition: all .3s ease-in-out;
}

#reserveTop .infomationBox .linkBtnBox .linkBtn a {
  display: inline-block;
  width: 100%;
  padding: 1.0981vw;
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox .linkBtnBox .linkBtn a {
    padding: 15px;
  }
}

@media screen and (min-width: 1366px) {
  #reserveTop .infomationBox .linkBtnBox .linkBtn a {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .linkBtnBox .linkBtn a {
    font-size: 2.13333vw;
  }
}

#reserveTop .infomationBox .linkBtnBox .linkBtn:hover {
  background-color: #333;
}

#reserveTop .infomationBox .linkBtnBox .linkBtn:hover a {
  color: #fff;
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox {
    width: 86.66667vw;
    max-width: 650px;
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #reserveTop .infomationBox p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .telBox {
    margin-top: 10.66667vw;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox .telBox {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox .telBox {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .telBox a {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox .telBox a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #reserveTop .infomationBox .telBox a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .inner {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox .inner {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .inner p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox .inner p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #reserveTop .infomationBox .inner p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .linkBtnBox {
    width: 62.66667vw;
    max-width: 470px;
  }
  #reserveTop .infomationBox .linkBtnBox .linkBtn {
    width: 62.66667vw;
    max-width: 470px;
  }
  #reserveTop .infomationBox .linkBtnBox .linkBtn a {
    width: 100%;
    padding: 2.66667vw;
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox .linkBtnBox .linkBtn a {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox .linkBtnBox .linkBtn a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #reserveTop .infomationBox .linkBtnBox .linkBtn a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #reserveTop .infomationBox .linkBtnBox .linkBtn:nth-of-type(1) {
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #reserveTop .infomationBox .linkBtnBox .linkBtn:nth-of-type(1) {
    margin-bottom: 50px;
  }
}

#menuTop .keyVisualSlider {
  position: relative;
  display: none;
}

#menuTop .keyVisualSlider.slick-initialized {
  display: block;
}

#menuTop .keyVisualSlider.slick-initialized:before {
  content: "";
  position: absolute;
  bottom: 5.12445vw;
  width: 5.85652vw;
  max-width: 80px;
  height: 36.60322vw;
  max-height: 500px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5;
  background-image: url(/images/menu/menu_fv_txt_pc.svg);
  background-size: cover;
  background-position: center;
}

@media screen and (min-width: 1366px) {
  #menuTop .keyVisualSlider.slick-initialized:before {
    bottom: 70px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .keyVisualSlider.slick-initialized:before {
    width: 13.33333vw;
    max-width: 100px;
    height: 69.33333vw;
    max-height: 520px;
    bottom: 5.86667vw;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .keyVisualSlider.slick-initialized:before {
    bottom: 44px;
  }
}

#menuTop .mainContentLogo {
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #menuTop .mainContentLogo {
    margin-bottom: 40px;
  }
}

#menuTop .menuMainBoxWrap {
  width: 85.35871vw;
  max-width: 1166px;
  margin-bottom: 7.32064vw;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap {
    margin-bottom: 100px;
  }
}

#menuTop .menuMainBoxWrap:nth-of-type(1) {
  margin-bottom: 8.78477vw;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap:nth-of-type(1) {
    margin-bottom: 120px;
  }
}

#menuTop .menuMainBoxWrap h2 {
  text-align: center;
  font-size: 2.4rem;
  font-size: 1.75695vw;
  margin-bottom: 2.19619vw;
  font-weight: bold;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap h2 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap h2 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap h2 {
    margin-bottom: 30px;
  }
}

#menuTop .menuMainBoxWrap .price {
  display: block;
  text-align: center;
  font-size: 2.4rem;
  font-size: 1.75695vw;
  margin-bottom: 4.39239vw;
  font-weight: bold;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .price {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .price {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .price {
    margin-bottom: 60px;
  }
}

#menuTop .menuMainBoxWrap .menuMainBox {
  height: 23.42606vw;
  max-height: 320px;
  display: flex;
  flex-wrap: wrap;
}

#menuTop .menuMainBoxWrap .menuMainBox img {
  width: 50.36603vw;
  max-width: 688px;
  object-fit: cover;
}

#menuTop .menuMainBoxWrap .menuMainBox .inner {
  width: 34.99268vw;
  max-width: 478px;
  padding: 2.34261vw;
  background-color: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner {
    padding: 32px;
  }
}

#menuTop .menuMainBoxWrap .menuMainBox .inner p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  text-align: start;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner p {
    font-size: 2.13333vw;
  }
}

#menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample {
  text-align: start;
  display: inline-block;
}

#menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample span {
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample span {
    font-size: 2.13333vw;
  }
}

#menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox {
  display: flex;
  flex-wrap: wrap;
}

#menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div p {
    font-size: 2.13333vw;
  }
}

#menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div:nth-of-type(1) {
  margin-right: 4.39239vw;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div:nth-of-type(1) {
    margin-right: 60px;
  }
}

#menuTop .menuMainBoxWrap .stampTxt {
  display: inline-block;
  margin-top: 5.12445vw;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .stampTxt {
    margin-top: 70px;
  }
}

#menuTop .menuMainBoxWrap .stampTxt p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  text-align: start;
}

@media screen and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .stampTxt p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .stampTxt p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .keyVisualSlider:before {
    bottom: 5.86667vw;
    width: 13.33333vw;
    max-width: 100px;
    height: 69.33333vw;
    max-height: 520px;
    background-image: url(/images/menu/menu_fv_txt.svg);
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .keyVisualSlider:before {
    bottom: 44px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .mainContentLogo {
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .mainContentLogo {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap {
    width: 86.66667vw;
    max-width: 650px;
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap h2 {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #menuTop .menuMainBoxWrap h2 {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap h2 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .price {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .price {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .price {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .price {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox {
    height: inherit;
    max-height: inherit;
  }
  #menuTop .menuMainBoxWrap .menuMainBox img {
    width: 86.66667vw;
    max-width: 650px;
  }
  #menuTop .menuMainBoxWrap .menuMainBox .inner {
    width: 86.66667vw;
    max-width: 650px;
    background-color: inherit;
    padding: 6.66667vw;
    align-items: baseline;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner {
    padding: 50px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample {
    margin-top: 6.66667vw;
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample span {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample span {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div {
    width: 100%;
    margin: 0;
  }
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div:nth-of-type(1) {
    margin: 0;
  }
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .menuMainBox .inner .menuSample .menuBox div p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .stampTxt {
    display: inline-block;
    margin-top: 13.33333vw;
    padding-left: 6.66667vw;
    padding-right: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .stampTxt {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .stampTxt {
    padding-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .stampTxt {
    padding-right: 50px;
  }
}

@media screen and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .stampTxt p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    text-align: start;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #menuTop .menuMainBoxWrap .stampTxt p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #menuTop .menuMainBoxWrap .stampTxt p {
    font-size: 3.73333vw;
  }
}
