@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */

// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1025px;

// 基本カラー
$basicColor: #333;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #707070;
$thinBorderColor: #ccc;
$bgColor: #f9f9f9;
$mainColor: #98c043;
$mediumfont: 300;
$footerbg:#f5f5f5;

// 基本フォント
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500;600;700&display=swap');
$base-font:"Noto Serif JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap');
// $en-font: 'Josefin Sans', sans-serif;

// $yakuhan:YakuHanJP_Noto,
// "Noto Sans JP",
// "ヒラギノ角ゴ Pro W3",
// "Hiragino Kaku Gothic ProN",
// "ヒラギノ角ゴシック",
// "Hiragino Kaku Gothic Pro",
// "メイリオ",
// Meiryo,
// Verdana,
// sans-serif;

// ブレークポイント定義
$breakpoints: ("md": "screen and (max-width : 767px)",
  "tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "pc": "screen and (min-width : 768px)",
  "fixed": "screen and (min-width : 1366px)",
  "maxFixed": "screen and (min-width : 1800px)",
) !default;

$properties: ('p': 'padding',
  'pt': 'padding-top',
  'pl': 'padding-left',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'm': 'margin',
  'mt': 'margin-top',
  'ml': 'margin-left',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'w': 'width',
  'h': 'height',
  't': 'top',
  'l': 'left',
  'b': 'bottom',
  'r': 'right',
) !default;

/* ================================================================================
importファイル
================================================================================ */

// @mixin定義
@import "foundation/mixin";

// @extend定義
@import "foundation/extend";

// リセット
@import "foundation/reset";

// 印刷
@import "foundation/print";

// 基本 
@import "layout/bace";

// ヘッダー
@import "layout/header";

// フッター
@import "layout/footer";

// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/common";

/************************************
トップページ
***********************************/

// トップページ
@import "page/index";

// aboutページ
@import "page/about";

// accessページ
@import "page/access";

// reserveページ
@import "page/reserve";

// menuページ
@import "page/menu";
