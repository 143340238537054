@charset "utf-8";

/* =====================================================
フッター
===================================================== */

#footer{
	position: relative;
	@include props(w,1166);
	@include props(pt,47);
	@include props(pb,20);
	margin: 0 auto;

	@include mq(md){
		@include props(w,650,750);
		@include props(pb,40,750);
		@include props(pt,120,750);
	}
	&:before{
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100vw;
		height: 100%;
		background-color: $basicColor;
		z-index: -1;
	}

	.mainInfonation{

		nav{
			display: flex;
			align-items: flex-start;
			&:before{
				content: "";
				background-image: url(/images/common/footer_logo.svg);
				display: block;
				@include props(w,100);
				@include props(h,100);
				@include props(mr,100);
				background-size: contain;
			}

			ul{
				@include props(mr,50);
				li{
					margin: .2em auto;

					a{
						display: inline-block;
						padding: .3em .2em;
						color: $basicWhiteColor;
						@include fz_vw(16);
					}
				}
			}

			.snsLink{
				display: flex;
				align-items: center;
				
				a{
					@include props(mr,25);

					&:first-of-type{
						@include props(ml,40);
						@include props(mr,20);
	
						img{
							@include props(w,29);
							@include props(h,29);
						}
					}

					img{
						@include props(w,34);
						@include props(h,24);
					}
				}
			}
		}
	}

	small{
		display: block;
		color: $basicWhiteColor;
		text-align: center;
		@include fz_vw(16);
		@include props(mt,84);

		@include mq(md){
			@include props(mt,0);
			@include fz_vw(32);
		}
	}
}