@charset "utf-8";

#accessTop{
	.infomationBox{
		@include props(w,1166);
		@include props(mb,100);
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		position: relative;
		
		&:before{
			content: "";
			background-color: $basicWhiteColor;
			position: absolute;
			@include props(t,100);
			left: 50%;
			transform: translate(-50%,0);
			width: 100vw;
			height: 100%;
			z-index: -1;
		}

		.inner{
			text-align: center;
			display: inline-block;
			@include props(mt,40);
			@include props(mb,80);
			
			p{
				text-align: start;
				@include props(mb,26);
				@include fz_vw(16);
				color: $borderColor;

				&:last-of-type{
					@include props(mb,0);
				}
			}
		}

		iframe{
			width: 100%;
			@include props(h,480);
			border: 1px solid #C6C6C6;
		}

		@include mq(md){

			&::before{
				display: none;
			}
			@include props(w,650,750);
			@include props(mb,140,750);

			.inner{
				@include props(mt,40,750);
				@include props(mb,60,750);
				text-align: start;
				display: block;

				p{
					@include fz_vw(28);
					@include props(mb,50,750);
				}
			}

			iframe{
				@include props(h,400,750);
			}
		}
	}
}