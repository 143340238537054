@charset "utf-8";

#menuTop{

	.keyVisualSlider{
		position: relative;
		display: none;

		&.slick-initialized{
			display: block;
			
			&:before{
				content: "";
				position: absolute;
				@include props(b,70);
				@include props(w,80);
				@include props(h,500);
				left: 50%;
				transform: translate(-50%,0);
				z-index: 5;
				background-image: url(/images/menu/menu_fv_txt_pc.svg);
				background-size: cover;
				background-position: center;

				@include mq(md){
					@include props(w,100,750);
					@include props(h,520,750);
					@include props(b,44,750);
					background-size: cover;
				}
			}
		}
	}

	.mainContentLogo{
		@include props(mb,40);
	}

	.menuMainBoxWrap{
		@include props(w,1166);
		@include props(mb,100);
		margin-right: auto;
		margin-left: auto;
		text-align: center;

		&:nth-of-type(1){
			@include props(mb,120);
		}

		h2{
			text-align: center;
			@include fz_vw(24);
			@include props(mb,30);
			font-weight: bold;
		}

		.price{
			display: block;
			text-align: center;
			@include fz_vw(24);
			@include props(mb,60);
			font-weight: bold;
		}

		.menuMainBox{
			@include props(h,320);
			display: flex;
			flex-wrap: wrap;
			
			
			img{
				@include props(w,688);
				object-fit: cover;
			}

			.inner{
				@include props(w,478);
				@include props(p,32);
				background-color: $basicWhiteColor;
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				p{
					@include fz_vw(16);
					text-align: start;
				}

				.menuSample{
					text-align: start;
					display: inline-block;
					span{
						@include fz_vw(16);
					}

					.menuBox{
						display: flex;
						flex-wrap: wrap;

						div{

							p{
								@include fz_vw(16);
							}
							&:nth-of-type(1){
								@include props(mr,60);
							}
						}
					}
				}
			}
		}

		.stampTxt{
			display: inline-block;
			@include props(mt,70);
			p{
				@include fz_vw(16);
				text-align: start;
			}
		}
	}



	@include mq(md){

		.keyVisualSlider{
			&:before{
				@include props(b,44,750);
				@include props(w,100,750);
				@include props(h,520,750);
				background-image: url(/images/menu/menu_fv_txt.svg);
			}
		}

		.mainContentLogo{
			@include props(mb,50,750);
		}

		.menuMainBoxWrap{
			@include props(w,650,750);
			@include props(mb,140,750);

			h2{
				@include fz_vw(28);
				@include props(mb,40,750);
			}

			.price{
				@include fz_vw(36);
				@include props(mb,40,750);
			}

			.menuMainBox{
				height: inherit;
				max-height: inherit;

				img{
					@include props(w,650,750);
				}

				.inner{
					@include props(w,650,750);
					background-color: inherit;
					@include props(p,50,750);
					align-items: baseline;

					p{
						@include fz_vw(28);
					}

					.menuSample{
						@include props(mt,50,750);
						display: block;
						width: 100%;

						span{
							@include fz_vw(28);
						}

						.menuBox{
							div{
								width: 100%;
								margin: 0;

								&:nth-of-type(1){
									margin: 0;
								}

								p{
									@include fz_vw(28);
								}
							}
						}
					}
				}
			}
			.stampTxt{
				display: inline-block;
				@include props(mt,100,750);
				@include props(pl,50,750);
				@include props(pr,50,750);
				p{
					@include fz_vw(28);
					text-align: start;
				}
			}
		}
	}
}