@charset "utf-8";

/* ==============================================
トップページ
============================================== */
// @include mq(md) {
// 	@include fz_vw(28);
// 	@include props(mb, 40, 750);
//   }

#indexTop{

	.fv{
		position: relative;
		
		&:after{
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			@include props(w,420);
			@include props(h,146);
			background-image: url(/images/index/index_movie_logo_pc.svg);
			background-size: contain;
			background-repeat: no-repeat;
		}

		.soundBtnBox{
			position: absolute;
			@include props(b,10);
			left: 50%;
			transform: translate(-50%,-100%);
			@include props(w,1166);
			
			#playBtn{
				@include props(w,120);
				@include props(h,42);
				margin: 0 0 0 auto;
				background-repeat: no-repeat;
				background-size: contain;

				&:hover{
					cursor: pointer;
				}
	
				&.soundOff{
					background-image: url(/images/index/index_soundoff.svg);
				}
	
				&.soundOn{
					background-image: url(/images/index/index_soundon.svg);
				}
			}
		}

		@include mq(md){
			&:after{
				background-image: url(/images/index/index_movie_logo.svg);
				@include props(w,150,750);
				@include props(h,465,750);
				top: inherit;
				@include props(b,90,750);
				transform: translate(-50%,0%);
			}
		}
	}

	.read{
		position: relative;
		margin: 0 auto;
		@include props(mt,70);
		@include props(mb,63);
		@include props(w,1166);
		@include props(pt,12);
		@include props(pb,12);
		letter-spacing: 3px;
		line-height: 3;
		

		&:before{
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: -1;
			width: 100vw;
			height: 100%;
			background-color: $basicWhiteColor;
		}

		.txtBox{
			display: flex;
			justify-content: center;
			flex-direction: row-reverse;
		}
		h2{
			@include fz_vw(24);
			font-weight: bold;
			writing-mode: vertical-rl;
			margin-left: 1em;
		}

		p{
			writing-mode: vertical-rl;
			padding-top: 1em;
			@include fz_vw(16);

			span{
				color: #9D0202;
				font-weight: bold;
			}

			&:nth-of-type(2){
				margin: 0 1em;
			}
		}

		@include mq(md){
			@include props(w,650,750);
			@include props(mb,20,750);
			line-height: 1.5;
			letter-spacing: 1px;
			text-align: center;

			&:before{
				display: none;
			}

			.txtBox{
				display: block;
			}
			h2{
				@include fz_vw(28);
				font-weight: bold;
				writing-mode: inherit;
				margin-left: 0;
				text-align: center;
			}
	
			p{
				text-align: center;
				writing-mode: inherit;
				padding-top: 1em;
				@include fz_vw(28);
	
				span{
					color: #9D0202;
				}
	
				&:nth-of-type(2){
					margin: 1em auto;
					text-align: start;
					display: inline-block;
				}
			}
		}
	}

	.navBox{
		@include props(w,1166);
		margin: 0 auto;
		@include props(mb,70);

		ul{
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			li{
				width: calc(100% / 2 - 0.58vw);
				position: relative;

				&:nth-child(1),&:nth-child(2){
					@include props(mb,25);
				}

				a{
					picture{
						width: 100%;
						@include props(h,240);
					}
					&:after{
						content: "お品書き";
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						@include fz_vw(24);
						color: $basicWhiteColor;
						width: 100%;
						text-align: center;
						line-height: 2;
						background-color: rgba(51,51,51,0.5);
						z-index: 3;
					}

					img{
						transition: all .3s ease-in-out;
						width: 100%;
						height: 100%;
					}

					&:hover{
						img{
							opacity: .7;
						}
					}
				}

				&:nth-child(2){
					a{
						&:after{
							content: "料理屋 染川について";
						}
					}
				}
				&:nth-child(3){
					a{
						&:after{
							content: "ご予約";
						}
					}
				}
				&:nth-child(4){
					a{
						&:after{
							content: "アクセス";
						}
					}
				}
			}
		}

		@include mq(md){
			@include props(w,650,750);
			@include props(mb,140,750);

			ul{
				li{
					width: 100%;
					@include props(mb,50,750);

					&:nth-child(1),&:nth-child(2){
						@include props(mb,50,750);
					}

					a{
						picture{
							@include props(w,554,750);
							@include props(h,256,750);
						}
						&:after{
							content: "お品書き";
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%,-50%);
							@include fz_vw(32);
							color: $basicWhiteColor;
							width: 100%;
							text-align: center;
							line-height: 2;
							background-color: rgba(51,51,51,0.5);
						}
					}
				}
			}

		}
	}

	.instagramBox{
		@include props(w,768);
		@include props(mb,100);
		margin-left: auto;
		margin-right: auto;

		h2{
			text-align: center;
			@include fz_vw(24);
			@include props(mb,36);
		}
		#instagramContent{
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			.instagramContentList{
				@include props(w,240);
				@include props(h,240);

				a{
					width: 100%;
					height: 100%;

					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				&:nth-child(1),&:nth-child(2),&:nth-child(3){
					@include props(mb,25);
				}
			}

			.instaError{
				width: 100%;
				margin: 0 auto;
				text-align: center;
				@include fz_vw(16);
			}
		}

		@include mq(md){
			@include props(w,650,750);
			@include props(mb,140,750);

			h2{
				@include fz_vw(28);
				@include props(mb,12,750);
			}

			#instagramContent{
				.instagramContentList{
					@include props(w,200,750);
					@include props(h,200,750);

					&:nth-child(1),&:nth-child(2),&:nth-child(3){
						@include props(mb,25,750);
					}
				}

				.instaError{
					@include fz_vw(24);
				}
			}
		}
	}
}