@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

.mainContentLogo{
	background-image: url(/images/common/main_logo_pc.svg);
	background-repeat: no-repeat;
	background-size: contain;
	@include props(w,104);
	@include props(h,104);
	@include props(mt,80);
	@include props(mb,70);
	margin-left: auto;
	margin-right: auto;

	@include mq(md){
		background-image: url(/images/common/main_logo.svg);
		@include props(w,86,750);
		@include props(h,86,750);
		@include props(mt,50,750);
		@include props(mb,50,750);
	}
}

.fadeIn{
	opacity: 0;
	transition: all 1s ease-in-out;
}

.fadeIn.active{
	opacity: 1;
}