@charset "utf-8";

#aboutTop{
	.ownerBox{
		@include props(w,1166);
		@include props(mb,100);
		margin-right: auto;
		margin-left: auto;
		display: flex;
		
		img{
			@include props(w,688);
			object-fit: cover;
		}

		.inner{
			@include props(p,32);
			background-color: $basicWhiteColor;

			strong{
				font-weight: bold;
				@include fz_vw(20);
				display: block;
				@include props(mb,30);
			}

			p{
				@include fz_vw(16);
				color: $borderColor;
			}
		}
	}

	@include mq(md){
		.ownerBox{
			@include props(w,650,750);
			@include props(mb,140,750);
			flex-direction: column;

			img{
				@include props(w,650,750);
			}

			.inner{
				@include props(pt,60,750);
				@include props(pb,0,750);
				@include props(pl,0,750);
				@include props(pr,0,750);
				background-color: inherit;

				strong{
					@include fz_vw(28);
					@include props(mb,40,750);
				}

				p{
					@include fz_vw(28);
				}
			}
		}
	}
}