@charset "utf-8";

#reserveTop{
	.infomationBox{
		@include props(w,1166);
		@include props(mb,100);
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		
		p{
			@include fz_vw(16);
			color: $borderColor;
		}

		.telBox{
			@include props(mt,30);
			@include props(mb,70);

			a{
				@include fz_vw(24);
			}
		}

		.inner{
			text-align: center;
			display: inline-block;
			@include props(mb,80);
			
			p{
				text-align: start;
				@include fz_vw(14);
				color: $borderColor;
			}
		}

		.linkBtnBox{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include props(w,515);
			margin: 0 auto;

			.linkBtn{
				width: calc(100% / 2 - 1.6105vw);
				border: 1px solid $basicColor;
				border-radius: 30px;
				background-color: $basicWhiteColor;
				transition: all .3s ease-in-out;

				a{
					display: inline-block;
					width: 100%;
					@include props(p,15);
					@include fz_vw(16);
				}

				&:hover{
					background-color: $basicColor;

					a{
						color: $basicWhiteColor;
					}
				}
			}
		}

		@include mq(md){
			@include props(w,650,750);
			@include props(mb,140,750);

			p{
				@include fz_vw(28);
			}

			.telBox{
				@include props(mt,80,750);
				@include props(mb,90,750);

				a{
					@include fz_vw(28);
				}
			}

			.inner{
				@include props(mb,120,750);

				p{
					@include fz_vw(28);
				}
				
			}

			.linkBtnBox{
				@include props(w,470,750);

				.linkBtn{
					@include props(w,470,750);

					a{
						width: 100%;
						@include props(p,20,750);
						@include fz_vw(28);
					}

					&:nth-of-type(1){
						@include props(mb,50,750);
					}
				}
			}
		}
	}
}